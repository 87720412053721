import Hero from "../components/Hero";
import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import { Circle, CircleFill } from "react-bootstrap-icons";
import ScrollAnimation from "react-animate-on-scroll";
import logo from "../logo/png_logo_line.svg";

const About = () => {
  const experiences = [
    {
      role: "Volunteering at Diakonie in the Achental",
      period: "2018 – 2021",
      location: "Unterwössen, Germany",
    },
    {
      role: "Graphic designer & Video Editing Alroyaa Company",
      period: "2016 – 2018",
      location: "Istanbul, Türkiye",
    },
    {
      role: "Social Media E-Marketing Alroyaa Company",
      period: "2017 – 2018 ",
      location: "Istanbul, Türkiye",
    },
    {
      role: "Freelancer For Graphic Designer Viva invist Real State",
      period: "2017 – 2018",
      location: "Istanbul, Türkiye",
    },
    {
      role: "Graphic designer SSG (Sosyal Soriye Groupları )",
      period: "2016 – 2017",
      location: "Adana, Türkiye",
    },
    {
      role: "Free Drawing Trainer SSG (sosyal Soriye Groupları )",
      period: "2016 – 2017",
      location: "Adana, Türkiye",
    },
    {
      role: "Mosaic Tayyar Art",
      period: "2015 – 2016",
      location: "Istanbul, Türkiye",
    },
  ];
  return (
    <React.Fragment>
      <Hero
        title="About"
        description="Hello My name is Abdulhadi Aldarwich, I study communication design and live in Dortmund. On my creative journey, I am constantly looking for ways to combine the elegance of minimalism with the excitement of playful design. I believe that simplicity doesn't have to be rigid or monotonous; it can coexist harmoniously with elements of playfulness and surprise."
      />
      <div className="bg-black pb-5 pt-5">
        <Container>
          <Row>
            <Col md={8}>
              <Container>
                <Row className="skills-languages-row">
                  <Col md={6} className="languages">
                    <ScrollAnimation
                      animateIn="animate__fadeInUp"
                      animateOnce={true}
                    >
                      <h2 className="xl-text text-white mt-xl-5">Languages</h2>
                    </ScrollAnimation>
                    <ScrollAnimation
                      animateIn="animate__fadeInLeft"
                      animateOnce={true}
                    >
                      <div className="language">
                        <span className="me-5 mt-xl-5 m-text text-white">
                          Arabic
                        </span>
                        <div className="proficiency mt-xl-5 description gap-3">
                          <CircleFill color="white" class="circleSize" />
                          <CircleFill color="white" class="circleSize" />
                          <CircleFill color="white" class="circleSize" />
                          <CircleFill color="white" class="circleSize" />
                          <CircleFill color="white" class="circleSize" />
                        </div>
                      </div>

                      <div className="language">
                        <span className="me-5 m-text text-white">German</span>
                        <div className="proficiency description gap-3">
                          <CircleFill color="white" class="circleSize" />
                          <CircleFill color="white" class="circleSize" />
                          <CircleFill color="white" class="circleSize" />
                          <CircleFill color="white" class="circleSize" />
                          <Circle color="white" class="circleSize" />
                        </div>
                      </div>

                      <div className="language">
                        <span className="me-5 m-text text-white">English</span>
                        <div className="proficiency description gap-3">
                          <CircleFill color="white" class="circleSize" />
                          <CircleFill color="white" class="circleSize" />
                          <Circle color="white" class="circleSize" />
                          <Circle color="white" class="circleSize" />
                          <Circle color="white" class="circleSize" />
                        </div>
                      </div>
                    </ScrollAnimation>
                  </Col>
                </Row>
              </Container>
              <Container className="experience-container">
                <ScrollAnimation
                  animateIn="animate__fadeInUp"
                  animateOnce={true}
                >
                  <h1 className="xl-text mb-5 pt-5 text-white">Experience</h1>
                </ScrollAnimation>
                {experiences.map((exp, index) => (
                  <ScrollAnimation
                    key={index}
                    animateIn="animate__fadeInUp"
                    animateOnce={true}
                  >
                    <div className="experience-entry mt-5 pb-5">
                      <div>
                        <h3 className="mb-3">{exp.role}</h3>
                        <h4 className="mb-3">{exp.period}</h4>
                        <h5>{exp.location}</h5>
                      </div>
                    </div>
                  </ScrollAnimation>
                ))}
              </Container>
            </Col>
            <Col md={4} className="align-self-center">
              <img src={logo} alt="logo" className="w-100" />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default About;
