import ScrollAnimation from "react-animate-on-scroll";

const Divider = (props) => {
  return (
    <div className="divider">
      <ScrollAnimation
        animateIn="animate__fadeInLeft"
        animateOnce={true}
        style={{ flex: "1" }}
      >
        <hr className="line" />
      </ScrollAnimation>
      <ScrollAnimation
        animateIn="animate__fadeIn"
        animateOnce={true}
        delay={1000}
      >
        <span className="number">{props.number}</span>
      </ScrollAnimation>
      <ScrollAnimation
        animateIn="animate__fadeInRight"
        animateOnce={true}
        style={{ flex: "1" }}
      >
        <hr className="line" />
      </ScrollAnimation>
    </div>
  );
};

export default Divider;
