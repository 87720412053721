import Hero from "../components/Hero";

const Contact = () => {
  return (
    <Hero
      title="Contact"
      description="Although I am good at graphic design, there are better ways to contact me and get an answer to your inquiry."
    />
  );
};

export default Contact;
