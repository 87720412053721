import "./App.css";
import CustomNavbar from "./components/CustomNavbar";
import ScrollToTop from "./components/ScrollToTop.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About.js";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Footer from "./components/Footer.js";
function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <CustomNavbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
