import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../logo/logo.svg";

const CustomNavbar = () => {
  const [visible, setVisible] = useState(true);
  const [prevScrollPos, setPrevScrollpos] = useState(window.scrollY);
  const [bgColor, setBgColor] = useState("transparent");
  const [expanded, setExpanded] = useState(false);

  const toggleNavCollapse = () => setExpanded(!expanded);

  const closeNav = () => setExpanded(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isVisible =
        prevScrollPos > currentScrollPos || currentScrollPos < 50;

      const newBgColor = currentScrollPos < 50 ? "transparent" : "black";
      setPrevScrollpos(currentScrollPos);
      setVisible(isVisible);
      setBgColor(newBgColor);
      closeNav();
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const order = ["/", "/about", "/projects", "/contact"];

  return (
    <Navbar
      className={`navbar ${visible ? "visible" : "hidden"}`}
      fixed="top"
      expanded={expanded}
      expand="lg"
      variant="light"
      style={
        bgColor === "transparent"
          ? { backgroundColor: "transparent" }
          : { backgroundColor: "rgba(0, 0, 0, 0.75)" }
      }
    >
      <Navbar.Brand href="/">
        <img src={logo} alt="logo" width={86} height={60} /> Aldarwich Studio
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={toggleNavCollapse}
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          {order.map((path, index) => (
            <LinkContainer key={index} to={path} onClick={closeNav}>
              <Nav.Link className="ms-4">
                {path === "/"
                  ? "Home"
                  : path.substring(1).charAt(0).toUpperCase() +
                    path.substring(2)}
              </Nav.Link>
            </LinkContainer>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
