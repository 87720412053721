import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
const Hero = (props) => {
  const spotlightSize = "transparent 10%, rgba(0, 0, 0, 0.9) 32%";

  useEffect(() => {
    const updateSpotlight = (e) => {
      const spotlight = document.querySelector(".spotlight");
      spotlight.style.backgroundImage = `radial-gradient(circle at ${
        (e.pageX / window.innerWidth) * 100
      }% ${(e.pageY / window.innerHeight) * 100}%, ${spotlightSize}`;
    };
    const handleMouseMove = (e) => {
      updateSpotlight(e);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [spotlightSize]);

  return (
    <div className="hero-section">
      <div className="spotlight"></div>
      <Container>
        <div className="hero-container">
          <h1 className="title animate__animated animate__fadeInUp">
            {props.title}
          </h1>
          <p className="description animate__animated animate__fadeInLeft">
            {props.description}
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
