import { Col, Container, Row } from "react-bootstrap";
import ScrollContainer from "./ScrollContainer";
import ScrollAnimation from "react-animate-on-scroll";

const DoubleColumn = (props) => {
  return (
    <Container>
      <Row className="justify-content-center justify-content-md-start">
        <Col md={8} className="projects-text-container">
          <ScrollAnimation
            animateIn="animate__fadeInUp"
            animateOnce={true}
            delay={800}
          >
            <h1
              className="sm-text text-break"
              dangerouslySetInnerHTML={{ __html: props.title }}
            ></h1>
          </ScrollAnimation>

          <ScrollAnimation
            animateIn="animate__fadeInLeft"
            animateOnce={true}
            delay={800}
          >
            <hr className="small-divider mt-4 mb-4" />
            <p className="s-text">{props.description}</p>
          </ScrollAnimation>
        </Col>
        <Col md={4} className="projects-text-container">
          <ScrollAnimation
            animateIn="animate__rotateInUpLeft"
            animateOnce={true}
          >
            <ScrollContainer images={props.images} />
          </ScrollAnimation>
        </Col>
      </Row>
    </Container>
  );
};

export default DoubleColumn;
