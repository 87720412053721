import React from "react";
import Hero from "../components/Hero";
import Divider from "../components/Divider";
import {
  superStimmulus,
  psychologicalComplex,
  portraits,
  posters,
  stadtbaukultur,
} from "../helpers/ImageImport";
import { Container } from "react-bootstrap";
import DoubleColumn from "../components/DoubleColumn";
import ScrollAnimation from "react-animate-on-scroll";

const Projects = () => {
  const projects = [
    {
      title: "Psychological <br /> Complex",
      description:
        "Welcome to my projects list! Here you will find an overview of some of my outstanding projects that I have realized throughout my professional journey. Each project represents not only my skills and expertise, but also my passion for graphic design.",
      images: psychologicalComplex,
    },
    {
      title: "Super <br /> Stimulus",
      description:
        "In a world where our attention is occupied by a variety of stimuli, we often encounter phenomena that influence our senses in fascinating ways. One such concept that plays a crucial role in the fields of biology, psychology and even design is the term “superstimulus”. The idea that certain stimuli or signals have a more intense effect on our perception than others opens the door to a deep understanding of how our senses react in extraordinary ways.",
      images: superStimmulus,
    },
    {
      title: "Don't Play <br /> With Logo",
      description:
        "Welcome to the renewed era of Deutsche Bank - a time where imagery not only tells stories, but also expresses values, innovation and our commitment to a sustainable financial future. Our fresh image reflects the dynamism and modernity that drives Deutsche Bank, while remaining committed to the tradition of trust and first-class service.",
      images: posters,
    },
    {
      title: "Free hand <br /> drawing",
      description:
        "“Free Hand Drawing Portrait” - a creative journey that captures the essence of personalities through the art of free drawing. In this collection of portraits I strive to capture not only the physical appearance but also the individual expressiveness of each individual using spontaneous strokes and artistic intuition.",
      images: portraits,
    },
    {
      title: "Stadtbau <br /> Kultur",
      description:
        "“Free Hand Drawing Portrait” - a creative journey that captures the essence of personalities through the art of free drawing. In this collection of portraits I strive to capture not only the physical appearance but also the individual expressiveness of each individual using spontaneous strokes and artistic intuition.",
      images: stadtbaukultur,
    },
  ];

  return (
    <React.Fragment>
      <Hero
        title="Projects"
        description="Aldarwich Studio ist ein Studio für Kommunikationsdesign mit Sitz in Dortmund. Wir entwickeln einzigartige Designkonzepte für alle Bereiche der Unternehmens- und Markenkommunikation. Ob Corporate Identity, Editorial Design oder digitale Lösung wir verknüpfen inhaltliche Arbeit mit anspruchsvoller und hochwertiger Gestaltung."
      />
      <Container className="projects-main-container logo">
        <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
          <h1 className="title text-black mb-5">Projectes</h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}>
          <p className="description">
            Welcome to my projects list! Here you will find an overview of some
            of my outstanding projects that I have realized throughout my
            professional journey. Each project represents not only my skills and
            expertise, but also my passion for graphic design.
          </p>
        </ScrollAnimation>
      </Container>

      {projects.map((project, index) => {
        return (
          <div key={index} className="mb-4">
            <Divider number={`0${index + 1}`} />
            <DoubleColumn
              title={project.title}
              description={project.description}
              images={project.images}
            />
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default Projects;
