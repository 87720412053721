import Hero from "../components/Hero";

const Home = () => {
  return (
    <Hero
      title="Portfolio"
      description="As a communications designer based in Germany, I have the unique opportunity to combine my love of design with a deep passion for art. My expertise lies in creating creative concepts and developing innovative solutions that not only impress aesthetically, but also function seamlessly and provide an exceptional user experience."
    />
  );
};

export default Home;
