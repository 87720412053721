import frontConver from "../images/cover-super-stimmulus.jpg";
import backCover from "../images/cover-end-super-stimmulus.jpg";
import super1 from "../images/super-stimmulus01.jpg";
import super2 from "../images/super-stimmulus02.jpg";
import super3 from "../images/super-stimmulus03.jpg";
import super4 from "../images/super-stimmulus04.jpg";
import super5 from "../images/super-stimmulus05.jpg";
import super6 from "../images/super-stimmulus06.jpg";
import super7 from "../images/super-stimmulus07.jpg";
import super8 from "../images/super-stimmulus08.jpg";
import super9 from "../images/super-stimmulus09.jpg";
import super10 from "../images/super-stimmulus010.jpg";
import super11 from "../images/super-stimmulus011.jpg";
import super12 from "../images/super-stimmulus012.jpg";
import super13 from "../images/super-stimmulus013.jpg";
import super14 from "../images/super-stimmulus014.jpg";
import super15 from "../images/super-stimmulus015.jpg";
import super16 from "../images/super-stimmulus016.jpg";
import super17 from "../images/super-stimmulus017.jpg";
import super18 from "../images/super-stimmulus018.jpg";
import negative from "../images/Negative.jpg";
import diana from "../images/Diana.jpg";
import elktra from "../images/Elktra.jpg";
import guilt from "../images/guilt.jpg";
import lolita from "../images/lolita.jpg";
import loneliness from "../images/Loneliness.jpg";
import loneliness2 from "../images/Loneliness-02.jpg";
import mutter from "../images/Mutter.jpg";
import persecution from "../images/persecution.jpg";
import portrait1 from "../images/portrait_01.png";
import portrait2 from "../images/portrait_02.png";
import portrait3 from "../images/portrait_03.png";
import portrait4 from "../images/portrait_04.png";
import portrait5 from "../images/portrait_05.png";
import portrait6 from "../images/portrait_06.png";
import poster1 from "../images/Poster_01.png";
import poster2 from "../images/Poster_02.png";
import poster3 from "../images/Poster_03.png";
import sbk1 from "../images/SBK_01.png";
import sbk3 from "../images/SBK_03.png";
import sbk4 from "../images/SBK_04.png";
import sbk7 from "../images/SBK_07.png";
import sbk8 from "../images/SBK_08.png";
import sbk9 from "../images/SBK_09.png";

export const superStimmulus = [
  frontConver,
  super1,
  super2,
  super3,
  super4,
  super5,
  super6,
  super7,
  super8,
  super9,
  super10,
  super11,
  super12,
  super13,
  super14,
  super15,
  super16,
  super17,
  super18,
  backCover,
];

export const psychologicalComplex = [
  negative,
  diana,
  elktra,
  guilt,
  lolita,
  loneliness,
  loneliness2,
  mutter,
  persecution,
];

export const portraits = [
  portrait5,
  portrait2,
  portrait3,
  portrait4,
  portrait6,
  portrait1,
];

export const posters = [poster1, poster2, poster3];

export const stadtbaukultur = [sbk1, sbk3, sbk4, sbk7, sbk8, sbk9];
