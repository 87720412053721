import React, { useState, useEffect, useRef } from "react";
import "../styles/scroll-container.css";

const ScrollContainer = ({ images }) => {
  const containerRef = useRef(null); // Reference to the scroll container
  const [containerHeight, setContainerHeight] = useState("auto");
  const imageRefs = useRef([]); // Array to hold refs for all images

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setContainerHeight(`${entry.target.offsetHeight}px`);
          }
        });
      },
      {
        root: containerRef.current,
      }
    );

    imageRefs.current.forEach((img) => observer.observe(img));

    // Cleanup observer on component unmount
    return () => observer.disconnect();
  }, [images]); // Re-run when images change

  // Function to set ref for each image
  const setImageRef = (el, index) => {
    imageRefs.current[index] = el;
  };

  return (
    <div
      className="scroll-container"
      ref={containerRef}
      style={{ maxHeight: containerHeight }}
    >
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`project-${index}`}
          className="scroll-image"
          ref={(el) => setImageRef(el, index)}
        />
      ))}
    </div>
  );
};

export default ScrollContainer;
