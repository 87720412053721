import { Col, Container, Nav } from "react-bootstrap";
import logo from "../logo/logo_white.svg";
const Footer = () => {
  return (
    <div className="bg-black border-top">
      <Container>
        <footer className="row py-5">
          <Col md={6} xl={8}>
            <a
              href="/"
              className="d-flex align-items-center link-dark text-decoration-none"
            >
              <img src={logo} alt="logo" width={30} height={40} />
            </a>
            <p className="text-white mt-3">
              Aldarwich Studio &copy; {new Date().getFullYear()}
            </p>
          </Col>
          <Col md={3} xl={2} className="mb-4">
            <h2 className="text-white">Contact</h2>
            <p className="text-white pt-2 footer-font">Aldarwich Studio</p>
            <a
              href="mailto:a.aldarwich93@gmail.com"
              className="text-white text-break footer-font"
            >
              a.aldarwich93@gmail.com
            </a>
          </Col>
          <Col md={3} xl={2}>
            <h2 className="text-white text-nowrap">Discover more</h2>
            <Nav className="d-flex flex-column footer-font">
              <Nav.Link href="/" className="text-white ps-0">
                Home
              </Nav.Link>
              <Nav.Link href="/about" className="text-white ps-0">
                About
              </Nav.Link>
              <Nav.Link href="/projects" className="text-white ps-0">
                Projects
              </Nav.Link>
              <Nav.Link href="/contact" className="text-white ps-0">
                Contact
              </Nav.Link>
            </Nav>
          </Col>
        </footer>
      </Container>
    </div>
  );
};

export default Footer;
